import './App.css';
import React, { useState, useEffect, createContext, lazy, Suspense } from 'react';
import { Route, Routes, HashRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BeatLoader from 'react-spinners/BeatLoader';
import AnimatedCursor from 'react-animated-cursor';
import Switch from 'react-switch';

import ScrollToTop from './components/ScrollToTop';

const Home = lazy(() => import('./pages/Home.js'));
const Sinoproject = lazy(() => import('./pages/Sinoproject.js'));
const Albertproject = lazy(() => import('./pages/Albertproject.js'));
const Scubaproject = lazy(() => import('./pages/Scubaproject.js'));
const Irimakiproject = lazy(() => import('./pages/Irimakiproject.js'));
const Mizcomprasproject = lazy(() => import('./pages/Mizcomprasproject.js'));
const Finbiproject = lazy(() => import('./pages/Finbiproject.js'));
const Okovichproject = lazy(() => import('./pages/Okovichproject.js'));
const Amorcitaproject = lazy(() => import('./pages/Amorcitaproject.js'));
const Picassoproject = lazy(() => import('./pages/Picassoproject.js'));
const Flamencoproject = lazy(() => import('./pages/Flamencoproject.js'));
const Koolproject = lazy(() => import('./pages/Koolproject.js'));
const NotFound = lazy(() => import('./pages/NotFound.js'));

// -------- Dark mode ---------- //
export const ThemeContext = createContext(null);


function App() {

  // -------- Translation ---------- //
  const [t, i18n] = useTranslation('global');


  // -------- Loading ---------- //
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1200)
  }, [])


  // -------- Dark mode ---------- //
  const [theme, setTheme] = useState('light');
  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
  }
  useEffect(() => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setTheme('dark');
    }
    // Add event listener to detect if dark mode is enabled/disabled on device
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      setTheme(e.matches ? 'dark' : 'light');
    }
    // Conditional for compatibility
    if (mediaQuery?.addEventListener) {
      mediaQuery.addEventListener('change', handleChange);
    } else {
        mediaQuery.addListener(handleChange);
    }
    // Remove event listener on unmount
    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    }
  }, []);


  return (
  
  <ThemeContext.Provider value={{theme, setTheme}}>
    <div className="App" id={theme}>

{/* -------- Page Sections ---------- */}
    <div className="site">

{/* -------- Pages ---------- */}
<HashRouter basename="/">
 <ScrollToTop />
 <Suspense fallback={<BeatLoader color={'#ff6699'} loading={loading} size={20} aria-label='Loading Spinner' data-testid='loader' />}>
  <Routes>

    <Route path='/' element={<Home theme={theme}/>} />
    <Route path='/sinodreams' element={<Sinoproject theme={theme}/>} />
    <Route path='/alberthern' element={<Albertproject theme={theme}/>} />
    <Route path='/scubatoto' element={<Scubaproject theme={theme}/>} />
    <Route path='/irimaki' element={<Irimakiproject theme={theme}/>} />
    <Route path='/mizcompras' element={<Mizcomprasproject theme={theme}/>} />
    <Route path='/finbi' element={<Finbiproject theme={theme}/>} />
    <Route path='/okovich' element={<Okovichproject theme={theme}/>} />
    <Route path='/amorcita' element={<Amorcitaproject theme={theme}/>} />
    <Route path='/picasso' element={<Picassoproject theme={theme}/>} />
    <Route path='/flamenco' element={<Flamencoproject theme={theme}/>} />
    <Route path='/koolargento' element={<Koolproject theme={theme}/>} />
    <Route path='*' element={<NotFound theme={theme} />} />
   
  </Routes>
  </Suspense>
</HashRouter>


{/* -------- Dark Mode Switch ---------- */}

      <Switch 
      onChange={toggleTheme} 
      checked={theme === "dark"} 
      handleDiameter={22}
      width={60}
      className='mode-switch'
      uncheckedIcon={
        <svg xmlns="http://www.w3.org/2000/svg" width="60%" height="60%" fill="#333" className="bi bi-sun" viewBox="0 0 16 16">
          <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"/>
        </svg>
      }
      checkedIcon={
        <svg xmlns="http://www.w3.org/2000/svg" width="50%" height="50%" fill="#f2f2f2" className="bi bi-moon-stars-fill" viewBox="0 0 16 16">
          <path d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z"/>
          <path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.734 1.734 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.734 1.734 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.734 1.734 0 0 0 1.097-1.097l.387-1.162zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L13.863.1z"/>
        </svg>
      }
      activeBoxShadow='0 0 2px 3px #ff6699'
      aria-label='Dark mode switch'
      />

{/* -------- Cursor Animation ---------- */}

      <AnimatedCursor color='255, 102, 153' 
      outerSize={200}
      outerScale={0}
      outerAlpha={0.3}
      hasBlendMode={true}
      outerStyle={{
        zIndex: '0',
        WebkitFilter: 'blur(90px)'
      }}
      />

    </div>
 {/*  )} */ }

    </div>
  </ThemeContext.Provider>
  );
}

export default App;
